import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { showIntercomMessenger } from 'shared/helpers/Intercom';
import { WebViewAnchorButton } from 'shared/routing/WebViewLinks';
import { useCarrierPerformanceIndicators } from '../data/CarrierPerformanceAPI';
import {
  calculateLateCancellationMeanRate,
  lateCancellationThresholds,
} from './PerformanceHelpers';

function LateCancellationTopPerformer() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">You are a top performer!</Typography>
      <Typography color="textSecondary">
        You consistently pickup loads you book without last minute
        cancellations!
        <br />
        <br />
        Your profile is standing out for Shippers on Super Dispatch.
      </Typography>
    </Stack>
  );
}

function LateCancellationGoodMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You&apos;re on the right track, but there&apos;s still room to improve.
      </Typography>
      <Typography color="textSecondary">
        Avoid canceling orders within 24 hours of the scheduled pickup time as
        this negatively impacts your reliability and results in wasted time and
        missed income opportunities.
      </Typography>
    </Stack>
  );
}

function LateCancellationCanImproveMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You have room for improvement on this metric.
      </Typography>
      <Typography color="textSecondary">
        Avoid canceling orders within 24 hours of the scheduled pickup time as
        this negatively impacts your reliability and results in wasted time and
        missed income opportunities.
      </Typography>
    </Stack>
  );
}

export default function LateCancellationDrawerContent() {
  const { data } = useCarrierPerformanceIndicators();

  const meanRate = useMemo(
    () => (data ? calculateLateCancellationMeanRate(data) : null),
    [data],
  );

  const variants = useMemo(
    () => (meanRate != null ? lateCancellationThresholds(meanRate) : null),
    [meanRate],
  );

  if (meanRate == null || variants == null) return null;

  return (
    <Stack space="small">
      {variants.top ? (
        <LateCancellationTopPerformer />
      ) : variants.good ? (
        <LateCancellationGoodMetrics />
      ) : (
        <LateCancellationCanImproveMetrics />
      )}
      <Stack space="xsmall">
        <Typography variant="h4">What is counted?</Typography>
        <Typography color="textSecondary">
          Loads canceled within 24 hours of pickup.
        </Typography>
      </Stack>
      <Stack space="xsmall">
        <Typography variant="h4">What is NOT counted?</Typography>
        <Typography color="textSecondary">
          Loads canceled within 2 hours of booking or when the reason for
          cancellation is &quot;Unit Not Ready&quot; or &quot;Unknown Inoperable
          Vehicle.&quot;
        </Typography>
      </Stack>
      <Stack space="small">
        <Typography variant="h4">Can we help?</Typography>
        <WebViewAnchorButton
          variant="text"
          endIcon={<ChevronRight />}
          target="_blank"
          href="https://support.superdispatch.com/en/articles/7913694-carrier-tms-carrier-performance-metrics"
        >
          Read our Article
        </WebViewAnchorButton>
        {variants.canImprove && (
          <Button
            variant="text"
            endIcon={<ChevronRight />}
            onClick={() => {
              showIntercomMessenger();
            }}
          >
            Talk to Us
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
